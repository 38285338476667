import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card, CardBody, CardTitle, CardText,
    Col, Container, Row,
    Input, Badge,
    Table,
    Nav, NavItem, NavLink
} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt, faFileUpload, faPlus} from '@fortawesome/free-solid-svg-icons';
import QuoteToolNavBar from "../molecules/QuoteToolNavBar.js";
import QuoteTable from "../molecules/QuoteTable.js";
import LoadingSpinner from "../atoms/LoadingSpinner.js";
import withRouter from "../../helper/withRouter.js";
import {toast} from "react-toastify";
import {Link} from "react-router-dom";
import account from "@kjdelectronics/ps-quotetool-domain/obj/account/Account.js";

class AccountPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            account: null
        }
    }

    componentDidMount() {
        this.getAccount();
    }

    async getAccount() {
        const account = await this.props.quoteToolClient.getAccountByIdOrReference(this.props?.router?.params?.accountId);
        this.setState({account});
    }

    render() {
        const {account} = this.state;

        if (!account)
            return <LoadingSpinner isLoading={true}/>

        return (
            <>
                <QuoteToolNavBar/>
                <Container style={{marginTop: '20px'}}>
                    <Row>
                        <Col>
                            <AccountName name={account.name} account={account}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <SystemCode code={account.formattedShortCode}/>
                        </Col>
                        <Col>
                            <AccountNumber number={account.reference}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <h3>Contacts</h3>
                            {account.contacts.map((contact, index) => (
                                <ContactCard key={index} contact={contact} isPrimary={index === 0}/>
                            ))}
                        </Col>
                        <Col md="6">
                            <h3>Addresses</h3>
                            {account.addresses.map((address, index) => (
                                <AddressCard key={index} address={address} isDefaultBilling={true}
                                             isDefaultShipping={true}/>
                            ))}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Documents</h3>
                            <DocumentUploader documents={account.documents}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Quotes <span className="fst-italic" style={{color: "black"}}>Currently does not filter by account and shows all quotes...</span></h3>
                            <QuoteTable quoteToolClient={this.props.quoteToolClient} filter={{"account.id": account.id}}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Statistics</h3>
                            <StatisticsCard stats={account.statistics}/>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

const NotSelectableLabel = () => (
    <span style={{userSelect: 'none', marginLeft: '5px'}}>
        {/* Non-selectable label */}
    </span>
)

// System Code Component
const SystemCode = ({code}) => (
    <Row>
        <Col>
            <Card>
                <CardBody>
                    <CardText><strong>System Code:</strong> <NotSelectableLabel/><span><code>{code}</code></span></CardText>
                </CardBody>
            </Card>
        </Col>
    </Row>
);

// Account Number Component
const AccountNumber = ({number}) => (
    number ? (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        <CardText><strong>Account Number:</strong> <NotSelectableLabel/><code>{number}</code></CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    ) : null
);

const AccountName = ({ name, account }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [currentName, setCurrentName] = useState(name || '');

    const toggleEditMode = () => setIsEditing(!isEditing);
    const handleBlur = () => setIsEditing(false);


    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2>Account - </h2>
                {isEditing ? (
                    <Input
                        type="text"
                        value={currentName}
                        onChange={(e) => setCurrentName(e.target.value)}
                        onBlur={handleBlur}
                        autoFocus
                        style={{ marginLeft: '10px', fontSize: '1.5rem', width: 'auto' }}
                    />
                ) : (
                    <div onClick={() => !name && toggleEditMode()} style={{ marginLeft: '10px', cursor: name ? 'default' : 'pointer', display: 'flex', alignItems: 'center' }}>
                        <h2 style={{ margin: 0 }}>
                            {name || <i style={{ color: 'gray' }}>Add an Account Name</i>}
                        </h2>
                        <FontAwesomeIcon
                            icon={faPencilAlt}
                            onClick={toggleEditMode}
                            style={{ marginLeft: '10px', cursor: 'pointer', fontSize: '1.5rem' }}
                        />
                    </div>
                )}
            </div>
            <Link to={ account.defaults.storeId != null ? `/stores/${account.defaults.storeId}/quotes?accountId=${account.id}` : ""}>
                <Button disabled={account.defaults.storeId == null} color="success" outline={true} style={{ marginLeft: '15px' }}>
              <FontAwesomeIcon icon={faPlus} style={{ color: 'green', marginRight: '5px' }} /> New Quote
            </Button>
            </Link>
        </div>
    );
};

// Contact Card Component
const ContactCard = ({contact, isPrimary}) => (
    <Card>
        <CardBody>
            <CardTitle>
                {contact.name} {isPrimary && <Badge color="primary">Primary Contact</Badge>}
            </CardTitle>
            <CardText>Email: {contact.email}</CardText>
            <CardText>Phone: {contact.phone}</CardText>
        </CardBody>
    </Card>
);

// Address Card Component
const AddressCard = ({address, isDefaultBilling, isDefaultShipping}) => (
    <Card>
        <CardBody>
            {isDefaultBilling && <Badge color="info" className="mx-1">Default Billing</Badge>}
            {isDefaultShipping && <Badge color="success" className="mx-1">Default Shipping</Badge>}
            <CardTitle>{address.firstName} {address.lastName}</CardTitle>
            <CardText>{address.line1}, {address.city}, {address.stateProvince}, {address.postalCode}, {address.country}</CardText>
        </CardBody>
    </Card>
);

// Document Uploader Component
const DocumentUploader = ({documents}) => (
    <div>
        <ul>
            {documents.map((doc, index) => (
                <li key={index}><a href={doc.link}>{doc.name}</a></li>
            ))}
        </ul>
        <div style={{border: '2px dashed gray', padding: '20px', textAlign: 'center', cursor: 'pointer'}}>
            <FontAwesomeIcon icon={faFileUpload}/> Drag & drop files or click to upload (Coming Soon...)
        </div>
    </div>
);

// Statistics Card Component
const StatisticsCard = ({stats}) => (
    <Card>
        <CardBody>
            <h3 style={{color: 'grey', fontStyle: 'italic'}}>Coming Soon...</h3>
            <CardText><strong>Total Quotes:</strong> ...</CardText>
            <CardText><strong>Total Revenue:</strong> ...</CardText>
            <CardText><strong>Total Profit:</strong> ...</CardText>
            <CardText><strong>Outstanding Balance:</strong>...</CardText>
        </CardBody>
    </Card>
);

AccountPage.propTypes = {
    quoteToolClient: PropTypes.object.isRequired,
};

export default withRouter(AccountPage);
